@import '../helpers/placeholders.scss';
@import '../helpers/variables.scss';

.Order {
    font-family: $lato;
    padding: 100px 0 50px;
    background-color: $brighterGrey;
    h1 {
        @extend %titles;
        margin: 3px auto 10px;
        width: 318px;
        text-align: center;
    }
    p {
        width: 460px;
        text-align: center;
        margin: 20px auto;
    }
    form {
        .__area {
            width: 520px;
            margin: 20px auto;
            display: flex;
            .__column {
                display: flex;
                flex-direction: column;
                width: 255px;
                input {
                    @extend %inputs;
                    height: 25px;
                }
            }
            textarea {
                @extend %inputs;
                width: 250px;
                margin-left: 5px;
            }
        }
        // button {
        #__button {
            display: block;
            margin: 20px auto;
            width: 80px;
            height: 30px;
            line-height: 25px;
            font-family: $lato;
            font-size: 15px;
            border-radius: 8px;
            background-color: $darkBlue;
            color: $grey;
            &:hover {
                cursor: pointer;
                background-color: $lightBlue;
                border: none;
                transition: all 700ms;
            }
        }
    }
}
@media(max-width: $threshold3) {
    .Order {
        h1 {
            margin: 3px auto 10px;
            width: calc(100% - 20px);
            line-height: 50px;
            padding: 0 10px;
        }
        p {
            width: 270px;
            text-align: center;
            margin: 20px auto;
        }
        form {
            .__area {
                width: 260px;
                flex-direction: column;
                .__column,
                textarea {
                    margin: 2px auto 0;
                }
                textarea {
                    position: relative;
                    right: 1px;
                }
            }
        }   
    }
}