@import '../helpers/variables.scss';

#slider {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: hsl(0, 0%, 0%, 0.8);
    display: flex;
    p {
        margin: 0;
        color: white;
        position: absolute;
        font-size: 160px;
        height: 140px;
        line-height: 140px;
        top: calc(50vh - 97px);
        &:hover {
            cursor: pointer;
        }
    }
    // Left arrow
    .left {
        left: calc(20% - 80px);
    }
    // Right arrow
    .right {
        right: calc(20% - 80px);
    }
    div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 60%;
            max-height: 80%;
            object-fit: cover;
        }
    }
}
@media(max-width: $threshold2) {
    #slider {
        p {
            top: calc(50vh - 97px);
            font-size: 120px;
        }
        .left {
            left: 2%;
        }
        .right {
            right: 2%;
        }
        div {
            img {
                width: 80%;
            }
        }
    }
}
@media(max-width: $threshold3) {
    #slider {
        p {
            top: calc(50vh - 84px);
            font-size: 90px;
        }
        .left {
            left: 3%;
        }
        .right {
            right: 3%;
        }
    }
}
@media(max-width: $threshold4) {
    #slider {
        p {
            top: calc(50vh - 80px);
            font-size: 60px;
        }
        .left {
            left: 3.5%;
        }
        .right {
            right: 3.5%;
        }
    }
}