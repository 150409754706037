@import '../helpers/placeholders.scss';
@import '../helpers/variables.scss';

.Gallery {
    margin-top: 100px;
    padding: 50px 0;
    background-color: $brighterGrey;
    h1 {
        @extend %titles;
        margin: 3px auto 10px;
        width: 105px;
    }
    .__grid {
        margin: 20px auto;
        width: 1000px;
        height: 400px;
        display: grid;
        grid-template: 49% 49% / 24.8% 24.8% 24.8% 24.8%;
        gap: 2% .6%;
        .__thumbnail {
            width: 100%;
            height: 100%;
            background-size: cover;  
            background-position: center;  
            div {
                background-color: black;
                height: 100%;
                width: 100%;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    opacity: 0.7;
                    transition: opacity 700ms;
                    cursor: pointer;
                }
                p {
                    text-align: center;
                    font-size: 18px;
                    font-family: $lato;
                    color: white;
                    padding: 0 5px;
                }
            }
        }
        .__thumbnail-2 {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-column-end: span 2;
            grid-row-end: 1;
        }
    }
}
@media (max-width: $threshold1) {
    .Gallery {
        .__grid {
            width: 800px;
            height: 320px;
        }
    }
}
@media (max-width: $threshold2) {
    .Gallery {
        .__grid {
            width: 500px;
            height: 1000px;
            grid-template: 24.5% 24.5% 24.5% 24.5% / 49% 49%;
            gap: .5% .6%;
            div[data-__number*="2"] {
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: span 2;
                grid-row-end: 1;
            }
        }
    }
}
@media (max-width: $threshold3) {
    .Gallery {
        .__grid {
            width: 430px;
            height: 750px;
        }
    }
}
@media (max-width: $threshold4) {
    .Gallery {
        .__grid {
            width: 260px;
            height: 500px;
        }
    }
}