@import '../helpers/variables';

.Menu {
    // Menu button
    p { 
        display: none;
    }
    .__flags {
        position: fixed;
        z-index: 4;
        right: 20px;
        top: 33px;
        img {
            height: 20px;
            width: 32px;
            padding: 0 4px;
        }
    }
    .__navBar {
        background-color: $darkBlue;
        height: 91px;
        width: 100vw;
        position: fixed;
        z-index: 3;
        top: 0;
        
        // Small logo
        img { 
            position: absolute;
            width: 300px;
            top: 5px;
            left: calc(50% - 380px);
            &:hover {
                cursor: pointer;
            }
        }
        .hidden {
            transform: translateX(-100%);
            opacity: 0;
            transition: all 1s;
        }
        .show {
            transform: translateX(0);
            opacity: 1;
            transition: all 1s; 
        }

        // Classes sticked to ul element
        .centered { 
            transition: transform 800ms;
        }
        .onRight { 
            transform: translateX(200px);
            transition: transform 800ms;
        }
        ul {
            margin: 32px auto 0;
            padding: 8px 0;
            width: 505px; 
            list-style-type: none;
            padding: 0 5px;
            li {
                display: inline-block;
                padding: 0 12px;
                font-family: $lato;
                font-size: 18px;
                color: white;
                &:hover {
                    color: $lightBlue;
                    cursor: pointer;
                }
            }
        }
    }
}
.Wallpaper {
    img {
        height: 800px;
        width: 100%;
        object-fit: cover;
        object-position: 50% 45%;
    }
}

@media (max-width: $threshold1) {
    .Menu {
        .__navBar {
            // Menu button
            p { 
                display: block;
                position: fixed;
                top: 11px;
                font-size: 20px;
                color: white;
                &:hover {
                    color: $lightBlue;
                    cursor: pointer;
                    transition: color 500ms;
                }
            }
            .middle {
                right: calc(50% - 42px);
                transition: right 800ms;
            }
            .moved {
                right: calc(50% - 250px);
                transition: right 800ms;
            }

            // Small logo
            img {
                left: calc(50% - 150px);
            }
            // Classes sticked to ul element
            .centered { 
                margin: 0;
                transition: all 800ms;
            }
            .onRight {
                margin: 0;
                transform: translateX(0px);
            }
            .ul-shown {
                z-index: 2;
                visibility: visible;
                opacity: 1;
            }
            .ul-hidden {
                z-index: 2;
                visibility: hidden;
                opacity: 0;
            }
            ul {
                height: 146px;
                background-color: $darkBlue;
                width: 100vw;
                position: absolute;
                top: 91px;
                border-top: 1px solid black;
                li {
                    display: block;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    &:hover {
                        background-color: $blue;
                    }
                }
            }
        }
    }
}
@media (max-width: $threshold2) {
    .Menu {
        .__navBar {
            // Small logo
            img { 
                display: none;
            }
            .moved {
                right: calc(50% - 42px);
            }
        }
    }
}
@media (max-width: $threshold3) {
    .Wallpaper {
        height: 430px;
        img {
            height: 430px;
            margin-top: 91px;
            object-fit: cover;
            object-position: top;
        }
    }
}
@media (max-width: $threshold4) {
    .Menu {    
        .__flags {
            img {
                padding: 0 8px; 
            }
        }
        .__navBar {
            .middle,
            .moved {
                right: calc(50% - 42px);
            }
        }
    }
}
@media (max-width: 350px) {
    .Menu {    
        .__flags {
            right: 5px;
            img {
                padding: 0 4px; 
            }
        }
    }
}