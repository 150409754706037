@import '../helpers/placeholders.scss';
@import '../helpers/variables.scss';

.Contact {
    margin: 0px auto;
    padding: 50px 0;
    h1 {
        @extend %titles;
        margin: 20px auto;
        width: 120px;
    }
    .__data {
        width: 1000px;
        display: flex;
        margin: 10px auto;
        justify-content: space-around;
    }
    .__group-wider {
        width: 250px;
    }
    .__group-narrower {
        width: 150px;
    }
    .__group-wider,
    .__group-narrower {
        font-family: $lato;
        text-align: center;
    }
    p {
        margin: 5px 0;
    }
    .__maps {
        width: 1000px;
        height: 300px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        iframe {
            height: 100%;
            width: 100%;
        }
    }
}
@media(max-width: $threshold1) {
    .Contact {
        .__data {
            width: 820px;
        }
        .__maps {
            width: 820px;
        }
    }
}
@media(max-width: $threshold2) {
    .Contact {
        .__data {
            width: 520px;
            height: 340px;
            display: block;
            position: relative;
            z-index: 1;
            .__group-wider,
            .__group-narrower {
                display: inline-block;
                width: 49.6%;
            }
            .base {
                position: relative;
                left: 260px;
            }
            .office {
                position: relative;
                top: 182px;
                right: 260px;
            }
            .invoice {
                position: relative;
                bottom: 135px;
            }
        }
        .__maps {
            width: 520px;
            iframe {
                width: 50%;
            }
        }
    }
}
@media(max-width: $threshold3) {
    .Contact {
        .__data {
            width: 260px;
            height: 530px;
            .__group-wider,
            .__group-narrower {
                display: block;
                width: 100%;
            }
            .base {
                left: 0;
            }
            .office {
                top: 0;
                right: 0;
            }
            .invoice {
                bottom: 0;
            }
        }
        .__maps {
            width: 90%;
            height: 620px;
            flex-direction: column;
            iframe {
                width: 100%;
                height: 49%;
                display: block;
            }
        }
    }
}

@media(max-width: $threshold4) {
    .Contact {
        .__maps {
            margin: 0 auto;
            width: 96%;
        }
    }
}