@import '../helpers/variables.scss';

%titles {
    text-decoration: underline;
    text-underline-offset: 10px;
    color: $darkBlue;
    font-family: $lato;
}

%inputs {
    width: 250px;
    line-height: 25px;
    font-size: 15px;
    color:   grey;
    margin: 2px 0;
    padding: 0;
    border: 1px solid black;
    border-radius: 2px;
    &::placeholder {
        font-family: $lato;
        text-indent: 4px;
    }
}