@import '../helpers/variables.scss';

.__modal {
    background-color: $darkBlue;
    height: 100vh;
    width: 100vw;
    padding: 0 5px;
    position: fixed;
    top: 0;
    z-index: 9;
    cursor: pointer;
    .__text {
        margin-top: 34vh;
        img {
            max-width: 190px;
            margin: 50px auto;
            display: block;
        }
        p {
            color: white;
            font-size: 20px;
            text-align: center;
            text-decoration: none;
        }
    }
}